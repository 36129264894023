import { Api, BOTS_BASE_URL } from '@/api/index';
import { getCompressedImage } from '@/utilities';
import axios from 'axios';
import { Product, ProductImage, UpdateProduct, UpdateProducts } from 'ignite360-core';

export class ProductApi {
  // static async createProduct(botId: string, data: CreateProduct): Promise<Product> {
  //   const config = Api.finalizeConfig({
  //     method: 'POST',
  //     url: `${BOTS_BASE_URL}/${botId}/products`,
  //     data,
  //   });
  //
  //   const result = await axios.request<Product>(config);
  //   if (result.status === 201 && result.data) {
  //     return result.data;
  //   }
  //   throw new Error('Could not create product');
  // }

  static async loadProducts(botId: string): Promise<Product[]> {
    const config = Api.finalizeConfig({
      method: 'GET',
      url: `${BOTS_BASE_URL}/${botId}/products`,
    });
    const result = await axios.request<Product[]>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    return [];
  }

  static async updateProducts(botId: string, products: Product[]): Promise<Product[]> {
    const data: UpdateProducts = { products };
    const config = Api.finalizeConfig({
      method: 'POST',
      url: `${BOTS_BASE_URL}/${botId}/products`,
      data,
    });

    const result = await axios.request<Product[]>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    throw new Error('Could not update products.');
  }

  static async uploadProductImages(
    botId: string,
    imageUpdateMap: Record<string, { square: File, horizontal: File }>,
  ): Promise<ProductImage[]> {
    const data = new FormData();
    const entries = Object.entries(imageUpdateMap);
    for (const entry of entries) {
      data.append(entry[0], await getCompressedImage(entry[1].horizontal));
      data.append(`${entry[0]}_square`, await getCompressedImage(entry[1].square));
    }
    const config = Api.finalizeConfig({
      method: 'POST',
      url: `${BOTS_BASE_URL}/${botId}/products/images`,
      data,
    });

    const result = await axios.request<ProductImage[]>(config);
    if (result.status === 201 && result.data) {
      return result.data;
    }
    throw new Error(`Could not upload image${entries.length > 1 ? 's' : ''}.`);
  }

  static async updateProduct(
    botId: string,
    productId: string,
    data: UpdateProduct,
  ): Promise<Product> {
    const config = Api.finalizeConfig({
      method: 'PUT',
      url: `${BOTS_BASE_URL}/${botId}/products/${productId}`,
      data,
    });

    const result = await axios.request<Product>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    throw new Error('Could not update product.');
  }

  static async deleteProduct(botId: string, productId: string): Promise<Product> {
    const config = Api.finalizeConfig({
      method: 'DELETE',
      url: `${BOTS_BASE_URL}/${botId}/products/${productId}`,
    });

    const result = await axios.request<Product>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    throw new Error('Could not delete product.');
  }

  static async deleteProductImage(
    botId: string,
    productId: string,
    imageId: string,
  ): Promise<ProductImage> {
    const config = Api.finalizeConfig({
      method: 'DELETE',
      url: `${BOTS_BASE_URL}/${botId}/products/${productId}/images/${imageId}`,
    });

    const result = await axios.request<ProductImage>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    throw new Error('Could not delete product-image.');
  }
}
