
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import isEqual from 'fast-deep-equal/es6';
import { BotDomain, DeepPartial, DomainData, RestaurantDomainDataContent } from 'ignite360-core';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';

type MenuSettings = Pick<RestaurantDomainDataContent, 'categoriesText' | 'menuUrl'>;

@Component({
  name: 'restaurant-menu-settings-card',
})
export default class RestaurantMenuSettingsCard extends mixins(BotMixin) {
  menuSettings: MenuSettings = this.resetValue();

  isSubmitting = false;

  get settingsHaveChanged(): boolean {
    return !isEqual(this.menuSettings, this.resetValue());
  }

  async onSubmit() {
    this.isSubmitting = true;
    try {
      await this.$updateBot({
        id: this.bot.id,
        data: {
          domainData: {
            [BotDomain.Restaurant]: this.menuSettings,
          } as DeepPartial<DomainData>,
        },
      });
      this.$notify.success('Successfully updated menu-settings');
    } catch (e) {
      this.$notify.error({
        title: 'Updating menu-settings failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  @Watch('bot', { immediate: true, deep: true })
  private reset() {
    this.menuSettings = this.resetValue();
  }

  private resetValue(): MenuSettings {
    return {
      menuUrl: this.bot.domainData[BotDomain.Restaurant]?.menuUrl || '',
      categoriesText: {
        message: this.bot.domainData[BotDomain.Restaurant]?.categoriesText?.message || '',
        prompt: this.bot.domainData[BotDomain.Restaurant]?.categoriesText?.prompt || '',
      },
    };
  }
}
