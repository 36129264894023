
import AnnouncementImageDropzone from '@/components/announcement/AnnouncementImageDropzone.vue';
import { Category, CategoryImage } from 'ignite360-core';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'restaurant-menu-categories-list-item',
  components: { AnnouncementImageDropzone },
})
export default class RestaurantMenuCategoriesListItem extends Vue {
  @Prop({ required: false, type: Boolean, default: true })
  readonly expanded!: boolean;

  @Prop({ required: true, type: Object })
  readonly category!: Category;

  @Prop({ required: true, type: Number })
  readonly index!: number;

  imageSource = '';
  synonymsText = '';

  onImageInput(files: { square: File, horizontal: File }) {
    this.imageSource = files.square ? URL.createObjectURL(files.square) : '';
    this.$emit('image-input', files);
  }

  onSynonymsInput() {
    this.category.synonyms = this.synonymsText
      .split(',')
      .map((text) => text.trim())
      .filter((text) => !!text);
  }

  @Watch('category', { immediate: true })
  onCategoryChange() {
    this.synonymsText = (this.category.synonyms || []).join(',');
  }

  @Watch('category.images', { immediate: true, deep: true })
  onCategoryImagesChange(images: CategoryImage[]) {
    this.imageSource = images.length ? images[0].url : '';
  }
}
