
import AnnouncementImageDropzone from '@/components/announcement/AnnouncementImageDropzone.vue';
import { KeyValuePair, Product, ProductImage } from 'ignite360-core';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'restaurant-menu-products-list-item',
  components: { AnnouncementImageDropzone },
})
export default class RestaurantMenuProductsListItem extends Vue {
  @Prop({ required: false, type: Boolean, default: true })
  readonly expanded!: boolean;

  @Prop({ required: true, type: Object })
  readonly product!: Product;

  @Prop({ required: true, type: Number })
  readonly index!: number;

  imageSource = '';

  get notEmptyTags(): string[] {
    return (this.product.tags || []).filter((tag) => !!tag);
  }

  onImageInput(files: { square: File, horizontal: File }) {
    this.imageSource = files.square ? URL.createObjectURL(files.square) : '';
    this.$emit('image-input', files);
  }

  addDetailsPair() {
    const newPair: KeyValuePair = {
      key: '',
      value: '',
    };
    if (!this.product.details) {
      this.$emit('input', { ...this.product, details: [newPair] });
    } else {
      this.product.details.push(newPair);
    }
  }

  deleteDetailsPair(index: number) {
    this.product.details?.splice(index, 1);
  }

  async addTag() {
    if (!this.product.tags) {
      this.$emit('input', { ...this.product, tags: [''] });
    } else {
      this.product.tags.push('');
    }
    await this.$nextTick();
    this.focusTag(this.product.tags!.length - 1);
  }

  focusTag(index: number) {
    // TODO check if selecting by index is sufficient
    const tagTextarea = (this.$refs.tagTextareas as Vue[] | undefined)?.[index];
    if (!tagTextarea) {
      return;
    }
    (tagTextarea as any).focus();
  }

  deleteTag(index: number) {
    this.product.tags?.splice(index, 1);
  }

  onPriceInput(val: string | number) {
    this.$emit('input', { ...this.product, price: typeof val === 'string' ? +val : val });
  }

  @Watch('product.images', { immediate: true, deep: true })
  onProductImagesChange(images: ProductImage[]) {
    this.imageSource = images.length ? images[0].url : '';
  }
}
