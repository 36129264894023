
import RestaurantMenuCategoriesListCard from '@/components/restaurant/RestaurantMenuCategoriesListCard.vue';
import RestaurantMenuProductsListCard from '@/components/restaurant/RestaurantMenuProductsListCard.vue';
import RestaurantMenuSettingsCard from '@/components/restaurant/RestaurantMenuSettingsCard.vue';
import { ProjectViewMixin } from '@/mixins/ProjectViewMixin';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'project-restaurant-menu',
  components: {
    RestaurantMenuProductsListCard,
    RestaurantMenuCategoriesListCard,
    RestaurantMenuSettingsCard,
  },
})
export default class ProjectRestaurantMenu extends mixins(ProjectViewMixin) {}
