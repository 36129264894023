import { Api, BOTS_BASE_URL } from '@/api/index';
import { getCompressedImage } from '@/utilities';
import axios from 'axios';
import { Category, CategoryImage, UpdateCategories, UpdateCategory } from 'ignite360-core';

export class CategoryApi {
  // static async createCategory(botId: string, data: CreateCategory): Promise<Category> {
  //   const config = Api.finalizeConfig({
  //     method: 'POST',
  //     url: `${BOTS_BASE_URL}/${botId}/categories`,
  //     data,
  //   });
  //
  //   const result = await axios.request<Category>(config);
  //   if (result.status === 201 && result.data) {
  //     return result.data;
  //   }
  //   throw new Error('Could not create category');
  // }

  static async loadCategories(botId: string): Promise<Category[]> {
    const config = Api.finalizeConfig({
      method: 'GET',
      url: `${BOTS_BASE_URL}/${botId}/categories`,
    });
    const result = await axios.request<Category[]>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    return [];
  }

  static async updateCategories(botId: string, categories: Category[]): Promise<Category[]> {
    const data: UpdateCategories = { categories };
    const config = Api.finalizeConfig({
      method: 'POST',
      url: `${BOTS_BASE_URL}/${botId}/categories`,
      data,
    });

    const result = await axios.request<Category[]>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    throw new Error('Could not update categories.');
  }

  static async uploadCategoryImages(
    botId: string,
    imageUpdateMap: Record<string, { square: File, horizontal: File }>,
  ): Promise<CategoryImage[]> {
    const data = new FormData();
    const entries = Object.entries(imageUpdateMap);
    for (const entry of entries) {
      data.append(entry[0], await getCompressedImage(entry[1].horizontal));
      data.append(`${entry[0]}_square`, await getCompressedImage(entry[1].square));
    }
    const config = Api.finalizeConfig({
      method: 'POST',
      url: `${BOTS_BASE_URL}/${botId}/categories/images`,
      data,
    });

    const result = await axios.request<CategoryImage[]>(config);
    if (result.status === 201 && result.data) {
      return result.data;
    }
    throw new Error(`Could not upload image${entries.length > 1 ? 's' : ''}.`);
  }

  static async updateCategory(
    botId: string,
    categoryId: string,
    data: UpdateCategory,
  ): Promise<Category> {
    const config = Api.finalizeConfig({
      method: 'PUT',
      url: `${BOTS_BASE_URL}/${botId}/categories/${categoryId}`,
      data,
    });

    const result = await axios.request<Category>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    throw new Error('Could not update category.');
  }

  static async deleteCategory(botId: string, categoryId: string): Promise<Category> {
    const config = Api.finalizeConfig({
      method: 'DELETE',
      url: `${BOTS_BASE_URL}/${botId}/categories/${categoryId}`,
    });

    const result = await axios.request<Category>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    throw new Error('Could not delete category.');
  }

  static async deleteCategoryImage(
    botId: string,
    categoryId: string,
    imageId: string,
  ): Promise<CategoryImage> {
    const config = Api.finalizeConfig({
      method: 'DELETE',
      url: `${BOTS_BASE_URL}/${botId}/categories/${categoryId}/images/${imageId}`,
    });

    const result = await axios.request<CategoryImage>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    throw new Error('Could not delete category-image.');
  }
}
