
import { CategoryApi } from '@/api/CategoryApi';
import RestaurantMenuCategoriesListItem from '@/components/restaurant/RestaurantMenuCategoriesListItem.vue';
import AnnouncementImageDropzone from '@/components/announcement/AnnouncementImageDropzone.vue';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import isEqual from 'fast-deep-equal/es6';
import { Category as CategoryBase } from 'ignite360-core';
import _cloneDeep from 'lodash.clonedeep';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import { AuthMixin } from '@/mixins/AuthMixin';

// workaround for making new categories unique
type Category = Omit<CategoryBase, 'id'> & { id: string | number };

@Component({
  name: 'restaurant-menu-categories-list-card',
  components: { RestaurantMenuCategoriesListItem, AnnouncementImageDropzone },
})
export default class RestaurantMenuCategoriesListCard extends mixins(BotMixin, AuthMixin) {
  loadedCategories: Category[] = [];
  categories: Category[] = [];

  categoryIdCount = 0;

  categoryImageUpdateMap: Record<Category['id'], { square: File, horizontal: File }> = {};

  isSubmitting = false;

  expandedCategoryIds: Category['id'][] = [];

  get categoriesHaveChanged(): boolean {
    return (
      !isEqual(this.categories, this.loadedCategories) ||
      !!Object.keys(this.categoryImageUpdateMap).length
    );
  }

  sortCategories = (a: Category, b: Category) => {
    const lastUpdatedA = new Date(a.lastUpdatedAt!).getTime();
    const lastUpdatedB = new Date(b.lastUpdatedAt!).getTime();
    if (lastUpdatedA > lastUpdatedB) {
      return -1;
    } else if (lastUpdatedA < lastUpdatedB) {
      return 1;
    }
    return 0;
  };
  filterCategories = (category: Category, filterBy: string) =>
    category.name.toLowerCase().includes(filterBy);
  getCategoryKey = (category: Category) => category.id;

  addCategory() {
    this.categories.push({
      id: ++this.categoryIdCount,
      name: '',
      tag: '',
      images: [],
      lastUpdatedAt: new Date(),
      description: '',
      synonyms: [],
    });
    this.setCategoryExpanded(this.categoryIdCount, true);
  }

  deleteCategory(category: Category) {
    const index = this.categories.findIndex((localCategory) => localCategory.id === category.id);
    if (index < 0) return;
    this.categories.splice(index, 1);
  }

  setCategoryImage(category: Category, files: { square: File, horizontal: File }) {
    this.$set(this.categoryImageUpdateMap, category.id, files);
  }

  isCategoryExpanded(categoryOrId: Category | Category['id']): boolean {
    const id = typeof categoryOrId === 'object' ? categoryOrId.id : categoryOrId;
    return this.expandedCategoryIds.includes(id);
  }

  setCategoryExpanded(categoryOrId: Category | Category['id'], expanded: boolean) {
    const id = typeof categoryOrId === 'object' ? categoryOrId.id : categoryOrId;
    const index = this.expandedCategoryIds.indexOf(id);
    if (index >= 0 && !expanded) {
      this.expandedCategoryIds.splice(index, 1);
    } else if (expanded) {
      this.expandedCategoryIds.push(id);
    }
  }

  async onSubmit() {
    this.isSubmitting = true;
    try {
      // update categories
      let categories = this.categories;
      if (!isEqual(this.categories, this.loadedCategories)) {
        categories = await CategoryApi.updateCategories(
          this.bot.id,
          this.categories.map((category) => {
            const copy = { ...category };
            if (!copy.id || typeof copy.id === 'number') {
              delete (copy as any).id;
            }
            return copy as CategoryBase;
          }),
        );

        // update imageUpdateMap
        this.categories.forEach((category, index) => {
          // if a new entry (typeof id === 'number') and an image was set for the category
          if (typeof category.id === 'number' && this.categoryImageUpdateMap[category.id]) {
            // update the image map to have the correct id instead of the numerical id
            this.categoryImageUpdateMap[categories[index].id] =
              this.categoryImageUpdateMap[category.id];
            // and delete the old numerical id-entry from the map
            delete this.categoryImageUpdateMap[category.id];
          }
        });
      }

      if (Object.keys(this.categoryImageUpdateMap).length) {
        const newImages = await CategoryApi.uploadCategoryImages(
          this.bot.id,
          this.categoryImageUpdateMap,
        );

        newImages.forEach((image) => {
          const category = categories.find((category) => category.id === image.category);
          if (!category) return;
          category.images = [image];
        });
      }

      this.categoryImageUpdateMap = {};
      this.setCategories(categories);
      this.$notify.success('Successfully updated categories');
    } catch (e) {
      this.$notify.error({
        title: 'Updating categories failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  @Watch('bot', { immediate: true })
  async reset() {
    await this.loadCategories();
  }

  private async loadCategories() {
    this.setCategories(await CategoryApi.loadCategories(this.bot.id));
  }

  private setCategories(categories: Category[]) {
    this.loadedCategories = categories;
    this.categories = _cloneDeep(this.loadedCategories);
  }
}
