
import { ProductApi } from '@/api/ProductApi';
import RestaurantMenuProductsListItem from '@/components/restaurant/RestaurantMenuProductsListItem.vue';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import isEqual from 'fast-deep-equal/es6';
import { Product as ProductBase } from 'ignite360-core';
import _cloneDeep from 'lodash.clonedeep';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import { AuthMixin } from '@/mixins/AuthMixin';

// workaround for making new products unique
type Product = Omit<ProductBase, 'id'> & { id: string | number };

@Component({
  name: 'restaurant-menu-products-list-card',
  components: { RestaurantMenuProductsListItem },
})
export default class RestaurantMenuProductsListCard extends mixins(BotMixin, AuthMixin) {
  loadedProducts: Product[] = [];
  products: Product[] = [];

  productIdCount = 0;

  productImageUpdateMap: Record<string, { square: File, horizontal: File }> = {};

  isSubmitting = false;

  expandedProductIds: Product['id'][] = [];

  get productsHaveChanged(): boolean {
    return (
      !isEqual(this.products, this.loadedProducts) ||
      !!Object.keys(this.productImageUpdateMap).length
    );
  }

  sortProducts = (a: Product, b: Product) => {
    const lastUpdatedA = new Date(a.lastUpdatedAt!).getTime();
    const lastUpdatedB = new Date(b.lastUpdatedAt!).getTime();
    if (lastUpdatedA > lastUpdatedB) {
      return -1;
    } else if (lastUpdatedA < lastUpdatedB) {
      return 1;
    }
    return 0;
  };
  filterProducts = (product: Product, filterBy: string) =>
    product.name.toLowerCase().includes(filterBy);
  getProductKey = (product: Product, index: number) => product.id || index;

  addProduct() {
    this.products.push({
      id: ++this.productIdCount,
      name: '',
      price: 0,
      images: [],
      lastUpdatedAt: new Date(),
      description: '',
      tags: [],
      url: '',
      details: [
        {
          key: 'allergens',
          value: '',
        },
        {
          key: 'calories',
          value: '',
        },
      ],
    });
    this.setProductExpanded(this.productIdCount, true);
  }

  updateProduct(product: Product) {
    const index = this.products.findIndex((localProduct) => localProduct.id === product.id);
    if (index < 0) return;
    this.products.splice(index, 1, product);
  }

  deleteProduct(product: Product) {
    const index = this.products.findIndex((localProduct) => localProduct.id === product.id);
    if (index < 0) return;
    this.products.splice(index, 1);
  }

  setProductImage(product: Product, files: { square: File, horizontal: File }) {
    this.$set(this.productImageUpdateMap, product.id, files);
  }

  isProductExpanded(productOrId: Product | Product['id']): boolean {
    const id = typeof productOrId === 'object' ? productOrId.id : productOrId;
    return this.expandedProductIds.includes(id);
  }

  setProductExpanded(productOrId: Product | Product['id'], expanded: boolean) {
    const id = typeof productOrId === 'object' ? productOrId.id : productOrId;
    const index = this.expandedProductIds.indexOf(id);
    if (index >= 0 && !expanded) {
      this.expandedProductIds.splice(index, 1);
    } else if (expanded) {
      this.expandedProductIds.push(id);
    }
  }

  async onSubmit() {
    this.isSubmitting = true;
    try {
      // update products
      let products = this.products;
      if (!isEqual(this.products, this.loadedProducts)) {
        products = await ProductApi.updateProducts(
          this.bot.id,
          this.products.map((product) => {
            const copy = { ...product };
            if (!copy.id || typeof copy.id === 'number') {
              delete (copy as any).id;
            }
            return copy as ProductBase;
          }),
        );

        // update imageUpdateMap
        this.products.forEach((product, index) => {
          // if a new entry (typeof id === 'number') and an image was set for the product
          if (typeof product.id === 'number' && this.productImageUpdateMap[product.id]) {
            // update the image map to have the correct id instead of the numerical id
            this.productImageUpdateMap[products[index].id] = this.productImageUpdateMap[product.id];
            // and delete the old numerical id-entry from the map
            delete this.productImageUpdateMap[product.id];
          }
        });
      }

      if (Object.keys(this.productImageUpdateMap).length) {
        const newImages = await ProductApi.uploadProductImages(
          this.bot.id,
          this.productImageUpdateMap,
        );

        // TODO: Change something here?
        newImages.forEach((image) => {
          const product = products.find((product) => product.id === image.product);
          if (!product) return;
          product.images = [image];
        });
      }

      this.productImageUpdateMap = {};
      this.setProducts(products);
      this.$notify.success('Successfully updated products');
    } catch (e) {
      this.$notify.error({
        title: 'Updating products failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  @Watch('bot', { immediate: true })
  async reset() {
    await this.loadProducts();
  }

  private async loadProducts() {
    this.setProducts(await ProductApi.loadProducts(this.bot.id));
  }

  private setProducts(products: Product[]) {
    this.loadedProducts = products;
    this.products = _cloneDeep(this.loadedProducts);
  }
}
